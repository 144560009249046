<template>
  <main>
    <div id="printContainer" class="main-grid">
      <div class="aside">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'home' }">
            <font-awesome-icon :icon="[ 'fas', 'home' ]" />
          </el-breadcrumb-item>
          <el-breadcrumb-item>管理</el-breadcrumb-item>
        </el-breadcrumb>
        <h4>管理</h4>
        <ul class="list list--aside">
          <li class="item">
            <router-link
              class="link"
              v-if="isShowAdminMenuItem==true"
              :class="{ active: $route.name=='manageAdmin' }"
              :to="{ name: 'manageAdmin' }">
              管理者功能
            </router-link>
          </li>
          <li class="item">
            <router-link
              class="link"
              :class="{ active: $route.name=='managePersonal' }"
              :to="{ name: 'managePersonal' }">
              個人資料
            </router-link>
          </li>
          <li class="item">
            <router-link
              class="link"
              v-if="isShowAdminMenuItem==true"
              :class="{ active: $route.name=='manageReport' }"
              :to="{ name: 'manageReport' }">
              問題回報
            </router-link>
          </li>
          <li class="item">
            <router-link
              class="link"
              :class="{ active: $route.name=='manageCustumKeywords' }"
              :to="{ name: 'manageCustumKeywords' }">
              潛在風險關鍵字
            </router-link>
          </li>
        </ul>
      </div>
      <div class="container">
        <router-view></router-view>
      </div>
    </div>
    <MainPageFloatButton>
    </MainPageFloatButton>
  </main>
</template>

<script>
// import * as apis from '@/apis/index.ts'
import { mapState, mapGetters } from 'vuex'
import MainPageFloatButton from '@/components/MainPageFloatButton/MainPageFloatButton.vue'

export default {
  name: 'admin',
  components: {
    MainPageFloatButton
  },
  data () {
    return {
      tab: 'departManage',
      // 單位管理 資料
      // adminUnitTabData: {}
    }
  },
  computed: {
    ...mapGetters([
      'getterIsManager'
    ]),
    isShowAdminMenuItem () {
      return this.getterIsManager
    }
  },
  mounted () {
    // apis.unitsGet({
    //   'company_sno': '',
    //   'country_no': ''
    // })
    //   .then(d => {
    //     this.adminUnitTabData = d.payload
    //   })
  }
}
</script>
